import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { useGetAllNichesQuery } from '../../api/generated';
import { FilterIcon } from '../../components/main/FilterIcon';
import NavBar from '../../components/main/Navbar/Navbar';
import { PaginationFooter } from '../../components/main/PaginationFooter';
import PathBar from '../../components/main/PathBar';
import ProfileBar from '../../components/main/ProfileBar';
import { SearchBar } from '../../components/main/SearchBar';
import { Filter } from '../../components/potentialSites/Filter';
import { PredefinedFilters } from '../../components/potentialSites/PredefinedFilters';
import { Table } from '../../components/potentialSites/Table';

export function PotentialSites() {
    const location = useLocation();
    const { clientID } = location.state;
    const { data, loading } = useGetAllNichesQuery();
    return (
        <>
            <NavBar activeElement="order" />
            <ProfileBar title="Potential Sites" />
            <div className="bg-zedex-bg-gray pt-20">
                <PathBar />
                <div className="Website-Outreach-Pool">
                    <Formik
                        // enableReinitialize
                        initialValues={{
                            paginationRowCount: 15,
                            pageNum: 0,
                            totalPages: 1,
                            totalRows: 0,
                            sortBy: 'siteUrl',
                            sortType: 'asc',
                            filterToggle: false,
                            statusFilters: ['Done', 'Negotiating'],
                            contactTypeFilter: ['ADMIN', 'THIRD-PARTY'],
                            serviceTypeFilter: ['GUEST/POST', 'LINK-INSERTION'],
                            nicheFilter: loading ? [] : data?.getAllNiches,
                            search: {
                                contains: '',
                            },
                        }}
                        onSubmit={() => {
                            //
                        }}
                    >
                        {() => (
                            <>
                                <div className="mt-3 space-x-5">
                                    <SearchBar placeholder="Search by Site URL, Site Contact Details and Employee Email" />
                                    <FilterIcon />
                                </div>
                                <Filter />
                                <PredefinedFilters />
                                <Table clientID={clientID} />
                                <PaginationFooter />
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
}
