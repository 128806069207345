import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
    useCreateMasterSheetMutation,
    useGetAllServiceTypeQuery,
    useGetEmployeeUsingTokenQuery
} from '../../api/generated';
import { AddNewDomainForm } from '../../components/addDomain/AddDomainForm';
import NavBar from '../../components/main/Navbar/Navbar';
import PathBar from '../../components/main/PathBar';
import ProfileBar from '../../components/main/ProfileBar';
import { addDomainSchema } from '../../form/AddDomainSchema';

export function AddNewDomain() {
    const [addDomain] = useCreateMasterSheetMutation();
    const navigate = useNavigate();
    const { data: serviceType } = useGetAllServiceTypeQuery();

    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();

    const linkInsertionID = serviceType?.getAllServiceType?.filter(
        (type) => type?.serviceType === 'LINK-INSERTION'
    )[0]?.id;

    return (
        <>
            <NavBar activeElement="outreach" />
            <ProfileBar title="Outreach Sheet" />
            <div className="bg-zedex-bg-gray pt-20">
                <PathBar />
                <div className="Website-Outreach-Pool">
                    <Formik
                        // enableReinitialize
                        initialValues={{
                            verifiedDomain: false,
                            siteUrl: '',
                            contactType: 1,
                            serviceType: 1,
                            siteDA: 0,
                            siteDR: 0,
                            siteTraffic: 0,
                            priceAsked: 0,
                            finalPrice: 0,
                            specialPrice: 0,
                            linkInsertionPrice: 0,
                            ahrefTraffic: 0,
                            nicheID: [],
                            contactedByID: undefined,
                            POC: '',
                            siteContactDetails: '',
                            dateOfContact: new Date()
                                .toISOString()
                                .substring(0, 10),
                            comments: '',
                            status: 'Negotiating',
                        }}
                        validateOnBlur
                        validationSchema={addDomainSchema}
                        onSubmit={async (values) => {
                            const { data } = await addDomain({
                                variables: {
                                    input: {
                                        domain: {
                                            siteUrl: values.siteUrl,
                                            siteDA: values.siteDA,
                                            siteDR: values.siteDR,
                                            siteTraffic: values.siteTraffic,
                                            ahrefTraffic: Number(
                                                values.ahrefTraffic
                                            ),
                                        },
                                        niches: values.nicheID,
                                        siteContactDetails:
                                            values.siteContactDetails.split(
                                                ','
                                            ),
                                        domainDetails: {
                                            contactedByID: LoggedInUser
                                                ?.getEmployeeUsingToken
                                                ?.id as number,
                                            contactTypeID: values.contactType,
                                            serviceTypeID: values.serviceType,
                                            POC: values.POC,
                                            linkInsertionPrice:
                                                values.serviceType ===
                                                linkInsertionID
                                                    ? values.linkInsertionPrice
                                                    : null,
                                            priceAsked: values.priceAsked,
                                            finalPrice: values.finalPrice,
                                            specialPrice: values.specialPrice,
                                            dateOfContact: new Date(
                                                values.dateOfContact
                                                    ? values.dateOfContact
                                                    : new Date()
                                                          .toISOString()
                                                          .substring(0, 10)
                                            ),
                                            status: values.status,
                                        },
                                        comments: values.comments,
                                    },
                                },
                            });
                            navigate('/admin/outreach-sheet');
                        }}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                <AddNewDomainForm />
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
}
