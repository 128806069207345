import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    MasterSheet,
    Role,
    useGetAllServiceTypeQuery,
    useGetEmployeeUsingTokenQuery,
    usePaginatedMasterSheetQuery,
} from '../../api/generated';
import '../../styles/WebsiteOutreachPool.css';
import { WebsiteOutreachPoolInitialValues } from '../../types/WebsiteOutreachPoolInitialValues';
import { CopyIcon } from '../main/CopyIcon';
import {
    TableHeaderColumnName,
    TableHeaderColumnSorting,
} from '../main/TableHeaderColumnSorting';

function TableRow(props: MasterSheet) {
    const formik = useFormikContext<WebsiteOutreachPoolInitialValues>();
    const contactTypeFilteredDomainDetails =
        formik.values.contactTypeFilter.length === 2
            ? props.domainDetails
            : props.domainDetails.filter((domainDetail) => {
                  return domainDetail?.contactType?.contactType?.includes(
                      formik.values.contactTypeFilter[0]
                  );
              });
    const [contactType, setContactType] = useState('ADMIN');
    const contactTypesAvailable: string[] = [];
    const domainDetails = contactTypeFilteredDomainDetails.filter(
        (domainDetail) => {
            contactTypesAvailable.push(
                domainDetail?.contactType?.contactType as string
            );
            return domainDetail?.contactType?.contactType;
        }
    );
    const { data: serviceType } = useGetAllServiceTypeQuery();
    const linkInsertionID = serviceType?.getAllServiceType?.filter(
        (type) => type?.serviceType === 'LINK-INSERTION'
    )[0]?.id;
    return (
        <tr className="hover:bg-blue-100">
            <td>
                <input
                    type="checkbox"
                    checked={formik.values.checkedRows.includes(props.siteUrl)}
                    onChange={(event) => {
                        if (event.target.checked) {
                            formik.setFieldValue('checkedRows', [
                                ...formik.values.checkedRows,
                                props.siteUrl,
                            ]);
                            const niches: string[] = [];
                            props?.DomainNiches.forEach((niche) =>
                                niches.push(niche?.niche?.niche as string)
                            );
                            props.domainDetails.forEach((domainDetail) => {
                                const siteContactDetails: string[] = [];
                                props.siteContactDetails
                                    .filter(
                                        (detail) =>
                                            detail?.contactType?.id ===
                                            domainDetail?.contactType.id
                                    )
                                    .forEach((detail) =>
                                        siteContactDetails.push(
                                            detail?.contactDetails as string
                                        )
                                    );
                                formik.setFieldValue('exportData', [
                                    ...formik.values.exportData,
                                    [
                                        props.siteUrl,
                                        props.siteDA.toString(),
                                        props.siteDR.toString(),
                                        props.siteTraffic.toString(),
                                        props.siteTraffic.toString(),
                                        domainDetail?.contactType
                                            .contactType as string,
                                        domainDetail?.serviceType
                                            .serviceType as string,
                                        (
                                            domainDetail?.finalPrice as number
                                        ).toString(),
                                        (
                                            domainDetail?.priceAsked as number
                                        ).toString(),
                                        (
                                            domainDetail?.specialPrice as number
                                        ).toString(),
                                        (
                                            domainDetail?.linkInsertionPrice ||
                                            0
                                        ).toString(),
                                        niches.join('/'),
                                        `${domainDetail?.contactedBy?.firstName} ${domainDetail?.contactedBy?.lastName}`,
                                        domainDetail?.POC as string,
                                        siteContactDetails.join('/'),
                                        domainDetail?.dateOfContact?.substring(
                                            0,
                                            10
                                        ),
                                        domainDetail?.status,
                                    ],
                                ]);
                            });
                        } else {
                            const filteredData =
                                formik.values.exportData.filter(
                                    (row) => row[0] !== props.siteUrl
                                );
                            formik.setFieldValue('exportData', filteredData);
                            formik.setFieldValue(
                                'checkedRows',
                                formik.values.checkedRows.filter(
                                    (url) => url !== props.siteUrl
                                )
                            );
                        }
                    }}
                />
            </td>
            <td className="site-url underline">
                <Link
                    to="/admin/outreach-sheet/domain"
                    state={{ domainID: props.id }}
                    className="space-x-2"
                    // onClick={(event) => event.preventDefault()}
                >
                    <span className="underline" title={props.siteUrl}>
                        {props.siteUrl.length > 12
                            ? props.siteUrl.substring(0, 12).concat('....')
                            : props.siteUrl}
                    </span>
                    <CopyIcon text={props.siteUrl} />
                </Link>
            </td>
            <td>{props.siteDA}</td>
            <td>{props.siteDR}</td>
            <td>{props.siteTraffic}</td>
            <td>{props.ahrefTraffic}</td>
            <td>
                <select
                    className="p-3 w-32 text-center bg-blue-50 hover:bg-blue-200"
                    onChange={(event) => setContactType(event.target.value)}
                >
                    {contactTypesAvailable.map((type) => (
                        <option className="p-3 w-32 text-center" key={type}>
                            {type}
                        </option>
                    ))}
                </select>
            </td>
            {domainDetails.map((domainDetail) => {
                return (
                    <>
                        <td>{domainDetail?.serviceType?.serviceType}</td>
                        <td>
                            {domainDetail?.serviceType?.id === linkInsertionID
                                ? domainDetail?.linkInsertionPrice
                                : 'NaN'}
                        </td>
                        <td>{`$${domainDetail?.priceAsked}`}</td>
                        <td>{`$${domainDetail?.finalPrice}`}</td>
                        <td>{`$${domainDetail?.specialPrice}`}</td>
                        <td>
                            {domainDetail?.contactedBy?.firstName?.concat(
                                ` ${domainDetail.contactedBy.lastName}`
                            )}
                        </td>
                        <td>{domainDetail?.POC}</td>
                        <td>{domainDetail?.dateOfContact.substring(0, 10)}</td>
                    </>
                );
            })}
            <td>
                {props.DomainNiches?.map((x, i) => (
                    <div className="block my-2" key={i.toString()}>
                        {x?.niche?.niche}
                    </div>
                ))}
            </td>
            <td>
                {props.siteContactDetails.map((x, i) =>
                    x?.contactType?.contactType ? (
                        <div className="block m-3" key={i.toString()}>
                            {x?.contactDetails}
                        </div>
                    ) : null
                )}
            </td>

            {domainDetails?.map((domainDetails) => {
                if (domainDetails?.status === 'Done') {
                    return (
                        <td key={domainDetails.status} className="font-bold">
                            <span className="block done-status p-4">Done</span>
                        </td>
                    );
                }
                if (domainDetails?.status === 'Negotiating') {
                    return (
                        <td key={domainDetails.status} className=" font-bold">
                            <span className="block nego-status p-4">
                                Negotiating
                            </span>
                        </td>
                    );
                }
                return <td key={domainDetails?.status} />;
            })}
        </tr>
    );
}

export function Table() {
    const formik = useFormikContext<WebsiteOutreachPoolInitialValues>();

    useEffect(() => {
        formik.setFieldValue('pageNum', 0);
    }, [formik.values.paginationRowCount]);

    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();

    const forEmployeeID =
        LoggedInUser?.getEmployeeUsingToken?.role === Role.Admin ||
        LoggedInUser?.getEmployeeUsingToken?.role === Role.Seoadmin
            ? -1
            : LoggedInUser?.getEmployeeUsingToken?.id;

    const { data, loading } = usePaginatedMasterSheetQuery({
        variables: {
            input: {
                take: Number(formik.values.paginationRowCount),
                skip:
                    Number(formik.values.pageNum) *
                    Number(formik.values.paginationRowCount),
                sortBy: formik.values.sortBy,
                sortType: formik.values.sortType,

                search: {
                    ...formik.values.search,
                },
                statusFilters: formik.values.statusFilters,
                serviceTypeFilter: formik.values.serviceTypeFilter,
                nicheFilter: formik.values.nicheFilter,
                siteDaFilter: {
                    ...formik.values.siteDAFilter,
                },
                siteDrFilter: {
                    ...formik.values.siteDRFilter,
                },
                siteTrafficFilter: {
                    ...formik.values.siteTrafficFilter,
                },
                finalPriceFilter: {
                    ...formik.values.finalPriceFilter,
                },
                specialPriceFilter: {
                    ...formik.values.specialPriceFilter,
                },
                priceAskedFilter: {
                    ...formik.values.priceAskedFilter,
                },
                dateOfContactFilter: {
                    ...formik.values.dateOfContactFilter,
                },
                forEmployeeID,
            },
        },
    });

    useEffect(() => {
        formik.setFieldValue(
            'totalPages',
            data?.paginatedMasterSheet?.totalPages
        );
    }, [data?.paginatedMasterSheet?.totalPages]);

    useEffect(() => {
        formik.setFieldValue(
            'totalRows',
            data?.paginatedMasterSheet?.totalRows
        );
    }, [data?.paginatedMasterSheet?.totalRows]);

    return (
        <div className="website-outreach-pool-table overflow-x-auto overflow-y-auto text-center bg-transparent">
            {loading ? (
                <div
                    className="rounded-lg p-5 bg-blue-100 text-blue-500
                    "
                >
                    Loading......
                </div>
            ) : data?.paginatedMasterSheet?.totalPages === 0 ? (
                <div
                    className="rounded-lg p-5 bg-blue-100 text-blue-500 text-center
                        "
                >
                    No Data To be Displayed
                </div>
            ) : (
                <table className="table-auto">
                    <thead className="table-header text-xs font-bold">
                        <tr className="space-x-2 overflow-x-auto">
                            <th className="edit-option">
                                <input
                                    className="align-middle"
                                    type="checkbox"
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            const rowData: string[][] = [];
                                            const checkedRows: string[] = [];
                                            data?.paginatedMasterSheet?.dms?.forEach(
                                                (row) => {
                                                    checkedRows.push(
                                                        row?.siteUrl as string
                                                    );
                                                    const niches: string[] = [];
                                                    row?.DomainNiches.forEach(
                                                        (niche) =>
                                                            niches.push(
                                                                niche?.niche
                                                                    ?.niche as string
                                                            )
                                                    );
                                                    row?.domainDetails.forEach(
                                                        (domainDetail) => {
                                                            formik.setFieldValue(
                                                                'checkedRows',
                                                                [
                                                                    ...formik
                                                                        .values
                                                                        .checkedRows,
                                                                    row?.siteUrl,
                                                                ]
                                                            );
                                                            const siteContactDetails: string[] =
                                                                [];
                                                            row.siteContactDetails
                                                                .filter(
                                                                    (detail) =>
                                                                        detail
                                                                            ?.contactType
                                                                            ?.id ===
                                                                        domainDetail
                                                                            ?.contactType
                                                                            .id
                                                                )
                                                                .forEach(
                                                                    (detail) =>
                                                                        siteContactDetails.push(
                                                                            detail?.contactDetails as string
                                                                        )
                                                                );
                                                            rowData.push([
                                                                row?.siteUrl as string,
                                                                row?.siteDA?.toString(),
                                                                row?.siteDR?.toString(),
                                                                row?.siteTraffic?.toString(),
                                                                domainDetail
                                                                    ?.contactType
                                                                    .contactType as string,
                                                                domainDetail
                                                                    ?.serviceType
                                                                    .serviceType as string,
                                                                (
                                                                    domainDetail?.finalPrice as number
                                                                ).toString(),
                                                                (
                                                                    domainDetail?.priceAsked as number
                                                                ).toString(),
                                                                (
                                                                    domainDetail?.specialPrice as number
                                                                ).toString(),
                                                                (
                                                                    domainDetail?.linkInsertionPrice ||
                                                                    0
                                                                ).toString(),
                                                                niches.join(
                                                                    '/'
                                                                ),
                                                                `${domainDetail?.contactedBy?.firstName} ${domainDetail?.contactedBy?.lastName}`,
                                                                domainDetail?.POC as string,
                                                                siteContactDetails.join(
                                                                    '/'
                                                                ),
                                                                domainDetail?.dateOfContact?.substring(
                                                                    0,
                                                                    10
                                                                ),
                                                                domainDetail?.status,
                                                            ]);
                                                        }
                                                    );
                                                }
                                            );
                                            formik.setFieldValue(
                                                'exportData',
                                                rowData
                                            );
                                            formik.setFieldValue(
                                                'checkedRows',
                                                checkedRows
                                            );
                                        } else {
                                            formik.setFieldValue(
                                                'checkedRows',
                                                []
                                            );
                                            formik.setFieldValue(
                                                'exportData',
                                                []
                                            );
                                        }
                                    }}
                                />
                            </th>
                            <TableHeaderColumnSorting
                                columnName="Site URL"
                                sortBy="siteUrl"
                            />
                            <TableHeaderColumnSorting
                                columnName="DA"
                                sortBy="siteDA"
                            />
                            <TableHeaderColumnSorting
                                columnName="DR"
                                sortBy="siteDR"
                            />
                            <TableHeaderColumnSorting
                                columnName="Semrush Traffic"
                                sortBy="siteTraffic"
                            />
                            <TableHeaderColumnName columnName="Ahref Traffic" />
                            <TableHeaderColumnName columnName="Contact Type" />
                            <TableHeaderColumnName columnName="Service Type" />
                            <TableHeaderColumnName columnName="Link Insertion Price" />
                            <TableHeaderColumnName columnName="Price Asked" />
                            <TableHeaderColumnName columnName="Final Price" />
                            <TableHeaderColumnName columnName="Special Price" />
                            <TableHeaderColumnName columnName="Contacted By" />
                            <TableHeaderColumnName columnName="POC" />
                            <TableHeaderColumnName columnName="Date of Contact" />
                            <TableHeaderColumnName columnName="Niche" />
                            <TableHeaderColumnName columnName="Site Contact Details" />
                            <TableHeaderColumnName columnName="Status" />
                        </tr>
                    </thead>
                    <tbody className="text-xs">
                        {data?.paginatedMasterSheet?.dms?.map(
                            (row) =>
                                row && (
                                    <TableRow
                                        id={row.id}
                                        siteUrl={row.siteUrl}
                                        siteDA={row.siteDA}
                                        siteDR={row.siteDR}
                                        ahrefTraffic={row.ahrefTraffic}
                                        siteTraffic={row.siteTraffic}
                                        createdAt={row.createdAt}
                                        updatedAt={row.updatedAt}
                                        siteContactDetails={
                                            row.siteContactDetails
                                        }
                                        DomainNiches={row.DomainNiches}
                                        domainDetails={row.domainDetails}
                                    />
                                )
                        )}
                    </tbody>
                </table>
            )}
        </div>
    );
}
