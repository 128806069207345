import {
    ArchiveBoxXMarkIcon,
    CheckIcon,
    EllipsisVerticalIcon,
    PencilSquareIcon,
} from '@heroicons/react/20/solid';
import {
    Package, SubPackage, ViewPackageQuery, useDisablePackageMutation, usePaginatedPackageListQuery, useViewPackageQuery
} from '../../api/generated';
import '../../styles/PackageSheet.css';

import { Formik, useFormikContext } from 'formik';
import { Link } from 'react-router-dom';
import { InitialValuesPackageList, NewPackageModal } from '../../components/Seopackage/NewPackageModal';
import NewSubPackageModal from '../../components/Seopackage/NewSubPackageModal';
import NavBar from '../../components/main/Navbar/Navbar';
import PathBar from '../../components/main/PathBar';
import ProfileBar from '../../components/main/ProfileBar';
import { SearchBar } from '../../components/main/SearchBar';
import { InitialValues } from '../../types/Initial';

export default function PackageSheet() {
    return (
        <div>
            <NavBar activeElement="inbox" />
            <ProfileBar title="Packages" />
            <div className="bg-[#f4f5fa] pt-20 ">
                <PathBar />
            </div>
            <h1 className="p-2 ml-20 text-xl font-sans">SEO Packages</h1>
            <Formik
                initialValues={{
                    search: {
                        contains: '',
                    },
                    showModal: false
                }}
                onSubmit={() => {
                    //
                }}
            >
                {() => (
                    <PackageList />
                )}
            </Formik>
        </div>
    );
}

function PackageList() {
    const formik = useFormikContext<InitialValuesPackageList>();
    const { data, refetch } = useViewPackageQuery({
        variables: {
            input: {
                contains: formik.values.search.contains,
            },
        },
    });
    return (
        <>
            <div className="ml-20 ">
                <SearchBar placeholder="Search by package name" />
                <NewPackageModal refetch={refetch} />
            </div>
            <br />
            <ViewPackage data={data} />
        </>

    )
}

function ViewPackage(props: { data: ViewPackageQuery | undefined }) {
    const formik = useFormikContext<InitialValues>();


    return (
        <div className="flex flex-col items-left ml-20 mr-10 object-left h-screen max-w-full">
            {props.data?.viewPackage?.map((packages) => (
                <Panel
                    key={packages.id}
                    description={packages?.description}
                    id={packages.id}
                    packageName={packages.packageName}
                    price={packages.price}
                    SubPackage={packages.SubPackage}
                    isDisabled={packages.isDisabled}
                />
            ))}
        </div>
    );
}

function Panel(props: Package) {
    const [disablePackage] = useDisablePackageMutation();

    // const [showModal, setShowModal] = React.useState(false);
    const style1 =
        'inline-block bg-transparent object-right hover:bg-blue float-right mr-3 text-blue-dark font-semibold rounded';
    const style2 =
        'inline-block bg-transparent object-right pointer-events-none hover:bg-blue float-right mr-3 text-blue-dark font-semibold rounded';

    const Enabled = (
        <span className="m-4 text-green-500 ">
            Status: <strong>Enabled</strong>
        </span>
    );
    const Disabled = (
        <span className="m-4 text-red ">
            Status: <strong>Disabled</strong>
        </span>
    );

    const formik = useFormikContext<InitialValues>();
    const { data, refetch } = useViewPackageQuery({
        variables: {
            input: {
                contains: formik.values.search.contains,
            },
        },
    });

    return (
        <div className="bg-white flex flex-col lg:w-full sm:w-3/4 border-b-slate-400">
            <details className="lg:w-full">
                <summary className="py-3 px-4 cursor-pointer w-full border-y-slate-400 border-blue-500 focus:ring-blue-600">
                    <span> Package {props.id}: </span>
                    <span> {props.packageName}</span>

                    <span className="m-4">
                        {props.isDisabled ? Disabled : Enabled}
                    </span>
                    <button
                        className="h-5 w-5 float-right"
                        type="button"
                        onClick={async () => {
                            const { data } = await disablePackage({
                                variables: {
                                    input: {
                                        id: props.id as number,
                                        isDisabled:
                                            !props.isDisabled as boolean,
                                    },
                                },
                            });
                            if (data?.disablePackage?.success) refetch();
                        }}
                    >
                        <ArchiveBoxXMarkIcon className="h-7 w-7 float-right p-1 mx-1 " />
                    </button>
                    <Link
                        to={{
                            pathname: '/updatepackage/'.concat(
                                (props?.id as number).toString() as string
                            ),
                        }}
                        className={props.isDisabled ? style2 : style1}

                    // type="button"
                    >
                        <PencilSquareIcon className="h-7 w-7 p-1 mx-1" />
                    </Link>

                    <NewSubPackageModal props={props} refetch={refetch} />
                </summary>

                <div className="lg:w-full sm:w-3/4 flex flex-row bg-slate-50 border border-slate-200  px-4 py-2 object-center items-center">
                    {props.SubPackage?.map(
                        (subPackages) =>
                            subPackages && (
                                <PanelDetails
                                    packageID={props.id as number}
                                    packageName={props.packageName as string}
                                    packagePrice={props.price as number}
                                    subPackage={subPackages}
                                    isDisabled={props.isDisabled as boolean}
                                />
                            )
                    )}
                </div>
            </details>
            {/* <Modal isVisible={showModal} /> */}
        </div>
    );
}

function PanelDetails(props: {
    packageID: number;
    packageName: string;
    packagePrice: number;
    isDisabled: boolean;
    subPackage: SubPackage;
}) {
    const formik = useFormikContext<InitialValues>();
    const style1 =
        'inline-block bg-transparent object-right hover:bg-blue float-right mr-3 text-blue-dark font-semibold rounded';
    const style2 =
        'inline-block bg-transparent object-right pointer-events-none hover:bg-blue float-right mr-3 text-blue-dark font-semibold rounded';
    const { data, loading } = usePaginatedPackageListQuery({
        variables: {
            input: {
                take: Number(formik.values.paginationRowCount),
                skip:
                    Number(formik.values.pageNum) *
                    Number(formik.values.paginationRowCount),
                sortBy: formik.values.sortBy,
                sortType: formik.values.sortType,
                search: formik.values.search,
            },
        },
    });
    return (
        <div className="w-full max-w-sm bg-white border border-white shadow-md mr-2 ">
            <Link
                to={{
                    pathname: '/updatesubpackage/'.concat(
                        (props?.subPackage.id as number).toString() as string
                    ),
                }}
                className={props.isDisabled ? style2 : style1}

            // type="button"
            >
                <EllipsisVerticalIcon className="h-6 w-6 inline-block bg-transparent object-right hover:bg-blue float-right text-blue-dark font-semibold rounded " />
            </Link>

            <div className="flex flex-col col-span-4 gap-5 items-center pb-10 mr-4 mt-4">
                <span className="text-sm text-gray-500 dark:text-gray-400 text-left">
                    Package {props.packageID}: {props.packageName}
                </span>
                <h5 className="mb-1 text-lg font-medium text-blue-600">
                    {props.subPackage.subPackageName}
                </h5>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                    {props.subPackage.requiredDA}
                </span>
                <span className=" grid grid-cols-2 gap-1 text-sm text-gray-500 dark:text-gray-400 ">
                    {props.subPackage.packageDescription
                        ?.split('\n')
                        .map((line) => (
                            <div className="mx-2" key={line}>
                                <CheckIcon
                                    className="h-6 w-6 inline-block bg-transparent object-left float-left font-semibold rounded mx-2"
                                    fill="#3ec170"
                                />
                                {line.substring(0, 25)}
                            </div>
                        ))}
                </span>
                <button
                    className="bg-blue-500 inline-block hover:bg-blue-700 text-white font-bold py-4 px-10 align-middle rounded m-4"
                    type="button"
                >
                    Price: $ {props.subPackage.price}
                </button>
            </div>
        </div>
    );
}
