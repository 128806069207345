import { useFormikContext } from 'formik';
import { SearchFilter } from '../../api/generated';

interface InitialValues {
    search: SearchFilter;
}

export function SearchBar(props: { placeholder: string; className?: string }) {
    const formik = useFormikContext<InitialValues>();
    return (
        <div
            className={`space-x-4 inline-block align-middle mt-2 pr-4 ${props.className}`}
        >
            <form
                className="inline-block  align-middle text-zedex-text-blue"
                onSubmit={(event) => event.preventDefault()}
            >
                <div className="relative w-3/4">
                    <button
                        type="button"
                        className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
                    >
                        <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                        </svg>
                    </button>
                    <input
                        type="search"
                        id="default-search"
                        className="block p-4 pl-10 w-[30rem] h-10 text-sm rounded-lg border border-blue-600"
                        placeholder={props.placeholder}
                        onChange={(event) => {
                            formik.setFieldValue(
                                'search.contains',
                                event.target.value
                            );
                        }}
                    />
                </div>
            </form>
        </div>
    );
}
