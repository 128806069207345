import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { Table } from '../../components/clientSelectedSites/Table';
import NavBar from '../../components/main/Navbar/Navbar';
import { PaginationFooter } from '../../components/main/PaginationFooter';
import PathBar from '../../components/main/PathBar';
import ProfileBar from '../../components/main/ProfileBar';
import { SearchBar } from '../../components/main/SearchBar';

export function ClientSelectedSites() {
    const location = useLocation();
    const { orderID } = location.state;
    return (
        <>
            <NavBar activeElement="order" />
            <ProfileBar title="Client Selected Sites" />
            <div className="bg-zedex-bg-gray pt-20">
                <PathBar />
                <div className="Website-Outreach-Pool">
                    <Formik
                        // enableReinitialize
                        initialValues={{
                            paginationRowCount: 15,
                            pageNum: 0,
                            totalPages: 1,
                            totalRows: 0,
                            search: {
                                contains: '',
                            },
                        }}
                        onSubmit={() => {
                            //
                        }}
                    >
                        {() => (
                            <>
                                <div className="mt-3 space-x-5">
                                    <SearchBar placeholder="Search by Site URL, Site Contact Details and Employee Email" />
                                </div>
                                <Table orderID={orderID} />
                                <PaginationFooter />
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
}
