/* eslint-disable react/button-has-type */
import { useFormik, useFormikContext } from 'formik';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useAddNewPackageMutation } from '../../api/generated';
import { SeoPackageSchema } from '../../form/SeoPackageSchema';
import InputComponent from '../main/Input';
import { InitialValuesPackageList } from './NewPackageModal';

export default function NewPackage({ refetch }: any) {
    const [addNewPackage] = useAddNewPackageMutation();
    const formikContext = useFormikContext<InitialValuesPackageList>();
    const formik = useFormik({
        initialValues: {
            packageName: '',
            description: '',
            price: 0,
        },
        validationSchema: SeoPackageSchema,
        onSubmit: async () => {
            const { data: addNewPackageData } = await addNewPackage({
                variables: {
                    input: {
                        packageName: formik.values.packageName,
                        description: formik.values.description,
                        price: formik.values.price,
                    },
                },
            });
            if (addNewPackageData?.addNewPackage?.success) {
                formikContext.setFieldValue('showModal', false);
                await refetch();
                toast.success('Your SEO package has been created successfully');
            }
        },
    });

    return (
        <div className="bg-white object-center align-middle rounded-lg">
            <div className=" bg-white h-full max-w-md mx-auto rounded-lg shadow-xl object-center align-middle">
                <form onSubmit={formik.handleSubmit}>
                    <h1 className="text-2xl px-2 ml-3 font-bold text-black-400 text-justify my-4">
                        Add New SEO Packages
                    </h1>
                    <div className="flex flex-col px-12 pb-6 w-full gap-3">
                        <div className="flex flex-col sm:flex-row rounded-md items-start justify-center mt-2">
                            <div className="w-full">
                                <label className="inline relative text-xl font-medium text-black-900 dark:text-gray-300  py-3 ">
                                    SEO Package Name :
                                </label>
                                <br />
                                <InputComponent
                                    inputType="text"
                                    id="packageName"
                                    name="packageName"
                                    inputPlaceholder="SEO Package Name"
                                    touched={formik.touched.packageName}
                                    onChange={formik.handleChange}
                                    value={formik.values.packageName}
                                    error={formik.errors.packageName}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row rounded-md object-center justify-center mt-2 ">
                            <div className="w-full">
                                <label className="inline relative text-xl font-medium text-black-900 dark:text-gray-300  py-3 ">
                                    SEO Package Description :
                                </label>
                                <br />
                                <InputComponent
                                    inputType="text"
                                    id="description"
                                    name="description"
                                    inputPlaceholder="SEO Package Description"
                                    touched={formik.touched.description}
                                    error={formik.errors.description}
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row rounded-md object-center justify-center mt-2">
                            <div className="w-full">
                                <label className="inline relative text-xl font-medium text-black-900 dark:text-gray-300  py-3 ">
                                    Package Coast:
                                </label>
                                <br />
                                <InputComponent
                                    inputType="number"
                                    inputPlaceholder="Price"
                                    name="price"
                                    touched={formik.touched.price}
                                    error={formik.errors.price}
                                    id="price"
                                    onChange={formik.handleChange}
                                    value={formik.values.price}
                                />
                            </div>
                        </div>
                        <div className="flex mt-3 flex-row gap-4 w-full">
                            <div className="flex w-full flex-col sm:flex-row rounded-md object-center justify-center ">
                                <Link
                                    to="/admin/packages"
                                    className=" text-center bg-transparent w-3/4 hover:bg-gray-200 text-blue-700 font-semibold py-2 px-4 ml-4 border border-blue-500 rounded mr-4"
                                    type="button"
                                    onClick={() =>
                                        formikContext.setFieldValue(
                                            'showModal',
                                            false
                                        )
                                    }
                                >
                                    Cancel
                                </Link>
                            </div>
                            <div className="flex w-full flex-col sm:flex-row rounded-md object-center justify-center">
                                <button
                                    className=" w-3/4  bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 border border-blue-700 rounded "
                                    type="button"
                                    onClick={() => formik.handleSubmit()}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
