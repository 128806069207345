import { useFormik } from 'formik';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    useGetSubPackageQuery,
    useUpdateSubPackageMutation
} from '../../api/generated';
import NavBar from '../main/Navbar/Navbar';
import ProfileBar from '../main/ProfileBar';

export default function UpdateSubPackage() {
    const [updateSubPackage, { data }] = useUpdateSubPackageMutation();
    // const { data: packages } = useViewPackageQuery();

    const { type } = useParams();

    const { data: subpackages, loading } = useGetSubPackageQuery({
        variables: {
            getSubPackageId: Number(type),
        },
    });
    const formik = useFormik({
        initialValues: {
            id: subpackages?.getSubPackage?.id as number,
            packageName: '',
            packageDescription: '',
            subPackageName: '',
            requiredDA: '',
            description: '',
            price: 0,
        },
        onSubmit: () => {
            updateSubPackage({
                variables: {
                    input: {
                        id: formik.values.id,
                        // packageName: formik.values.packageName,
                        packageDescription: formik.values.packageDescription,
                        subPackageName: formik.values.subPackageName,
                        requiredDA: formik.values.requiredDA,
                        price: formik.values.price,
                        // description: formik.values.description,
                    },
                },
            });
            window.location.href = '/admin/packages';
        },
    });

    useEffect(() => {
        formik.setFieldValue('id', subpackages?.getSubPackage?.id);
        formik.setFieldValue(
            'subPackageName',
            subpackages?.getSubPackage?.subPackageName
        );
        formik.setFieldValue(
            'packageDescription',
            subpackages?.getSubPackage?.packageDescription
        );
        formik.setFieldValue(
            'requiredDA',
            subpackages?.getSubPackage?.requiredDA
        );
        formik.setFieldValue(
            'description',
            subpackages?.getSubPackage?.packageDescription
        );
        formik.setFieldValue('price', subpackages?.getSubPackage?.price);
    }, [subpackages]);

    if (!loading) {
        return (
            <div>
                <NavBar activeElement="inbox" />
                <ProfileBar title="Packages" />
                <div className="bg-[#f4f5fa] pt-24 ">
                    <div className="flex-1 h-full max-w-md mx-auto bg-blue rounded-lg shadow-xl bg-white  ">
                        <Link to="/admin/packages"
                            className="bg-white rounded-md  float-right relative top-2 right-100 text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        >

                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </Link>
                        <form onSubmit={formik.handleSubmit}>
                            <h1 className="text-2xl px-2 mb-4 font-bold text-black-400 text-justify">
                                Update SEO Sub Package
                            </h1>
                            <div className="flex flex-col sm:flex-row rounded-md object-center justify-center ">
                                <div className="mb-4 ">
                                    <label className="inline relative text-xl font-medium text-black-900 dark:text-gray-300 px-3 py-3 ">
                                        SEO SubPackage Name :
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        id="subpackageName"
                                        name="subPackageName"
                                        className="form-control w-80 px-3 py-3 ml-2 mr-2 text-base font-normal text-gray-400 bg-gray bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600  focus:ring-blue-500 shadow-lg"
                                        onChange={formik.handleChange}
                                        value={formik.values.subPackageName}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col sm:flex-row rounded-md object-center justify-center ">
                                <div className="mb-4 ">
                                    <label className="inline relative text-xl font-medium text-black-900 dark:text-gray-300 px-3 py-3 ">
                                        Sub Package Description:
                                    </label>
                                    <br />
                                    <textarea
                                        name="packageDescription"
                                        id="packageDescription"
                                        className="form-control w-80 px-3 py-3 ml-2 mr-2 text-base font-normal text-gray-400 bg-gray bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600  focus:ring-blue-500 shadow-lg"
                                        cols={30}
                                        rows={5}
                                        onChange={formik.handleChange}
                                        value={formik.values.packageDescription}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row rounded-md object-center justify-center ">
                                <div className="mb-4 ">
                                    <label className="inline relative text-xl font-medium text-black-900 dark:text-gray-300 px-3 py-3 ">
                                        DA :
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        id="requiredDA"
                                        className="form-control w-80 px-3 py-3 ml-2 mr-2 text-base font-normal text-gray-400 bg-gray bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600  focus:ring-blue-500 shadow-lg"
                                        onChange={formik.handleChange}
                                        value={formik.values.requiredDA}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row rounded-md object-center justify-center ">
                                <div className="mb-4 ">
                                    <label className="inline relative text-xl font-medium text-black-900 dark:text-gray-300 px-3 py-3 ">
                                        Price Asked:
                                    </label>
                                    <br />
                                    <input
                                        type="number"
                                        id="price"
                                        className="form-control w-80 px-3 py-3 ml-2 mr-2 text-base font-normal text-gray-400 bg-gray bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600  focus:ring-blue-500 shadow-lg"
                                        onChange={formik.handleChange}
                                        value={formik.values.price}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="flex flex-col sm:flex-row rounded-md object-center justify-center ">
                                <Link to="/admin/packages"
                                    className=" bg-transparent w-3/4 hover:bg-gray-200 text-blue-700 font-semibold py-2 px-4 ml-4 border border-blue-500 rounded mr-4 text-center"
                                >
                                    Reset
                                </Link>
                            </div>
                            <br />
                            <div className="flex flex-col sm:flex-row rounded-md object-center justify-center">
                                <button
                                    className=" w-3/4  bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 border border-blue-700 rounded "
                                    type="submit"
                                >
                                    Save
                                </button>
                            </div>
                            <br />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    return null;
}
