import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { useAddSubPackageMutation } from '../../api/generated';

type NewPackageProps = {
    id: number | undefined;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: any;
};
export default function NewSubPackage({
    id,
    setShowModal,
    refetch,
}: NewPackageProps) {
    // const [showModal, setShowModal] = React.useState(false);
    const [addSubPackage, { data }] = useAddSubPackageMutation();
    const formik = useFormik({
        initialValues: {
            packageId: id,
            packageName: '',
            packageDescription: '',
            subPackageName: '',
            requiredDA: '',
            description: '',
            price: 0,
        },
        onSubmit: async () => {
            await addSubPackage({
                variables: {
                    input: {
                        packageId: Number(formik.values.packageId),
                        subPackageName: formik.values.subPackageName,
                        packageDescription: formik.values.packageDescription,
                        requiredDA: formik.values.requiredDA,
                        price: formik.values.price,
                    },
                },
            });
            // window.location.href = '/admin/packages';
            setShowModal(false);
            refetch();
        },
    });

    return (
        <div className="bg-white rounded-lg">
            <div className="flex-1 h-full max-w-md mx-auto  my-6 bg-blue rounded-lg shadow-xl">
                <form onSubmit={formik.handleSubmit} className="rounded-lg">
                    <h1 className="text-2xl px-2 mb-4 font-bold text-black-400 text-justify">
                        Add New SEO Sub Packages
                    </h1>
                    <div className="flex flex-col sm:flex-row rounded-md object-center justify-center ">
                        <div className="mb-4 ">
                            <label className="inline relative text-xl font-medium text-black-900 dark:text-gray-300 px-3 py-3 ">
                                Sub Package Name:
                            </label>
                            <br />
                            <input
                                type="text"
                                id="subPackageName"
                                className="form-control w-80 px-3 py-3 ml-2 mr-2 text-base font-normal text-gray-400 bg-gray bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600  focus:ring-blue-500 shadow-lg"
                                onChange={formik.handleChange}
                                value={formik.values.subPackageName}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row rounded-md object-center justify-center ">
                        <div className="mb-4 ">
                            <label className="inline relative text-xl font-medium text-black-900 dark:text-gray-300 px-3 py-3 ">
                                Sub Package Description:
                            </label>
                            <br />

                            <textarea
                                name="packageDescription"
                                id="packageDescription"
                                className="form-control w-80 px-3 py-3 ml-2 mr-2 text-base font-normal text-gray-400 bg-gray bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600  focus:ring-blue-500 shadow-lg"
                                cols={30}
                                rows={5}
                                onChange={formik.handleChange}
                                value={formik.values.packageDescription}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row rounded-md object-center justify-center ">
                        <div className="mb-4 ">
                            <label className="inline relative text-xl font-medium text-black-900 dark:text-gray-300 px-3 py-3 ">
                                Required DA:
                            </label>
                            <br />
                            <input
                                type="text"
                                id="requiredDA"
                                className="form-control w-80 px-3 py-3 ml-2 mr-2 text-base font-normal text-gray-400 bg-gray bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600  focus:ring-blue-500 shadow-lg"
                                onChange={formik.handleChange}
                                value={formik.values.requiredDA}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row rounded-md object-center justify-center ">
                        <div className="mb-4 ">
                            <label className="inline relative text-xl font-medium text-black-900 dark:text-gray-300 px-3 py-3 ">
                                Price Asked:
                            </label>
                            <br />
                            <input
                                type="number"
                                id="price"
                                className="form-control w-80 px-3 py-3 ml-2 mr-2 text-base font-normal text-gray-400 bg-gray bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600  focus:ring-blue-500 shadow-lg"
                                onChange={formik.handleChange}
                                value={formik.values.price}
                            />
                        </div>
                    </div>

                    <br />
                    <div className="flex flex-col sm:flex-row rounded-md object-center justify-center ">
                        <Link
                            to="/admin/packages"
                            type="button"
                            className=" text-center bg-transparent w-3/4 hover:bg-gray-200 text-blue-700 font-semibold py-2 px-4 ml-4 border border-blue-500 rounded mr-4"
                        >
                            Cancel
                        </Link>
                    </div>
                    <br />
                    <div className="flex flex-col sm:flex-row rounded-md object-center justify-center">
                        <button
                            className=" w-3/4  bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 border border-blue-700 rounded "
                            type="submit"
                        >
                            Save
                        </button>
                    </div>
                    <br />
                </form>
            </div>
        </div>
    );
}
