import { useFormikContext } from 'formik';

interface InitialValues {
    filterToggle: boolean;
}

export function FilterIcon() {
    const formik = useFormikContext<InitialValues>();
    return (
        <button
            type="button"
            className="w-32 border border-blue-200 focus:border-blue-600 hover:border-blue-600 hover:bg-blue-100 p-2 px-3 rounded-md ml-2 align-middle mt-2 space-x-2"
            onClick={() =>
                formik.setFieldValue(
                    'filterToggle',
                    !formik.values.filterToggle
                )
            }
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#5570F1"
                className="w-6 h-6 filterIcon inline-block"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                />
            </svg>
            <span>Filter</span>
        </button>
    );
}
