import { Link } from "react-router-dom";

export function AddDomainButton() {
    return (
        <Link to="/admin/outreach-sheet/add-domain" >
            <button
                type="button"
                className="bg-zedex-button-blue text-white create-order align-middle p-1.5 w-48 hover:bg-zedex-button-blue-hover mt-2"
            >
                <div className="space-x-2 align-middle">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-5 h-5 inline-block"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                        />
                    </svg>
                    <span className="">Add Domain</span>
                </div>
            </button>
        </Link>
    );
}
