import '../../styles/TableHeaderColumnSorting.css';

import { useFormikContext } from 'formik';
import { AscendSvg } from './Ascend';
import { DescendSvg } from './Descend';

interface InitialValues {
    pageNum: number;
    paginationRowCount: number;
    totalPages: number;
    totalRows: number;
    sortBy: string;
    sortType: string;
}

export function SortingButton(props: { sortBy: string }) {
    const formik = useFormikContext<InitialValues>();
    return (
        <button
            className="align-middle"
            type="button"
            onClick={() => {
                formik.setFieldValue(
                    'sortType',
                    formik.values.sortType === 'asc' ? 'desc' : 'asc'
                );
                formik.setFieldValue('sortBy', props.sortBy);
            }}
        >
            {formik.values.sortType === 'asc' &&
            formik.values.sortBy === props.sortBy ? (
                <AscendSvg />
            ) : (
                <DescendSvg />
            )}
        </button>
    );
}

export function TableHeaderColumnSorting(props: {
    columnName: string;
    sortBy: string;
}) {
    return (
        <th className="table-column-name space-y-2 py-3 px-1">
            <div className="align-middle">{props.columnName}</div>
            <SortingButton sortBy={props.sortBy} />
        </th>
    );
}

export function TableHeaderColumnName(props: { columnName: string }) {
    return (
        <th className="table-column-name space-y-2 py-3 px-2">
            <div className="align-middle">{props.columnName}</div>
        </th>
    );
}
