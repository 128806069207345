import { Formik } from 'formik';
import {
    Role,
    useGetAllNichesQuery,
    useGetEmployeeUsingTokenQuery,
} from '../../api/generated';
import { FilterIcon } from '../../components/main/FilterIcon';
import NavBar from '../../components/main/Navbar/Navbar';
import { PaginationFooter } from '../../components/main/PaginationFooter';
import PathBar from '../../components/main/PathBar';
import ProfileBar from '../../components/main/ProfileBar';
import { SearchBar } from '../../components/main/SearchBar';
import { AddDomainButton } from '../../components/websiteOutreachPool/AddDomainButton';
import { CategoryButton } from '../../components/websiteOutreachPool/CategoryButton';
import { ExportCSVButton } from '../../components/websiteOutreachPool/ExportCSVButton';
import { Filter } from '../../components/websiteOutreachPool/Filter';
import { PredefinedFilters } from '../../components/websiteOutreachPool/PredefinedFilters';
import { Table } from '../../components/websiteOutreachPool/Table';

export function WebsiteOutreachPool() {
    const { data, loading } = useGetAllNichesQuery();
    const { data: loggedInUser } = useGetEmployeeUsingTokenQuery();

    return (
        <>
            <NavBar activeElement="outreach" />
            <ProfileBar title="Outreach Sheet" />
            <div className="bg-zedex-bg-gray pt-20">
                <PathBar />
                <div className="Website-Outreach-Pool">
                    <Formik
                        // enableReinitialize
                        initialValues={{
                            paginationRowCount: 15,
                            pageNum: 0,
                            totalPages: 1,
                            totalRows: 0,
                            sortBy: 'siteUrl',
                            sortType: 'asc',
                            filterToggle: false,
                            statusFilters: ['Done', 'Negotiating'],
                            contactTypeFilter: ['ADMIN', 'THIRD-PARTY'],
                            serviceTypeFilter: ['GUEST/POST', 'LINK-INSERTION'],
                            nicheFilter: loading ? [] : data?.getAllNiches,
                            search: {
                                contains: '',
                            },
                            exportData: [],
                            allChecked: false,
                            checkedRows: [],
                            csvHeader: [
                                'Site URL',
                                'Site DA',
                                'Site DR',
                                'Site Traffic',
                                'Contact Type',
                                'Service Type',
                                'Final Price',
                                'Price Asked',
                                'Special Price',
                                'Link Insertion Price',
                                'Niches',
                                'Contacted By',
                                'POC',
                                'Site Contact Details',
                                'Date Of Contact',
                                'Status',
                            ],
                        }}
                        onSubmit={() => {
                            //
                        }}
                    >
                        {() => (
                            <>
                                <div className="mt-3 space-x-5">
                                    <SearchBar placeholder="Search by Site URL, Site Contact Details and Employee Email" />
                                    <FilterIcon />
                                    <AddDomainButton />
                                    {loggedInUser?.getEmployeeUsingToken
                                        ?.role === Role.Admin ? (
                                        <CategoryButton />
                                    ) : null}
                                    <ExportCSVButton />
                                </div>
                                <Filter />
                                <PredefinedFilters />
                                <Table />
                                <PaginationFooter />
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
}
