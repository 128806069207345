import { useFormik } from 'formik';
import { useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

import * as Yup from 'yup';
import { useLoginEmployeeMutation } from '../../api/generated';
import ButtonComp from '../../components/main/Button';
import FormComponent from '../../components/main/FormComponent';
import InputComponent from '../../components/main/Input';
import { EMAILS, REQUIREDS } from '../../components/main/Schema';
import { TOKEN } from '../../constants/storageKeys';

import { ToastContainer, toast } from 'react-toastify';
import '../../styles/style.css';

const loginSchema = Yup.object().shape({
    email: Yup.string().email(EMAILS).required(REQUIREDS),
    password: Yup.string().required(REQUIREDS),
});
export default function Login() {
    const [loginEmployee] = useLoginEmployeeMutation();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: loginSchema,
        onSubmit: async () => {
            const { data } = await loginEmployee({
                variables: {
                    input: {
                        email: formik.values.email,
                        password: formik.values.password,
                    },
                },
            });

            if (data?.loginEmployee?.success) {
                localStorage.setItem(
                    TOKEN,
                    data?.loginEmployee?.token as string
                );
                notify('Success');
                window.location.replace('/');
            } else {
                notify('Wrong Credentials, Try Again');
            }
        },
    });

    const [passwordEye, setPasswordEye] = useState(false);
    const handlePasswordClick = () => {
        setPasswordEye(!passwordEye);
    };
    const notify = (statusMsg: string) => toast(statusMsg);

    return (
        <div
            className="Background bg-cover bg-center"
            style={{
                backgroundImage: "url('/img/background.png')",
            }}
        >
            <div className="  flex  min-h-screen justify-center items-center  py-8 px-2 sm:px-4 lg:px-6 ">
                <div className="flex flex-col  md:flex-row md:max-w-xl rounded-lg bg-gray shadow-2xl">
                    <div className="flex items-center h-32 md:h-auto md:w-1/2 bg-gradient-to-r from-trev to-indigo rounded-lg">
                        <img src="/img/des.png" alt="" />
                    </div>
                    <div className="p-6 flex flex-col justify-start">
                        <form onSubmit={formik.handleSubmit}>
                            <img
                                className="mb-3 logo  "
                                src="/img/zedex.png"
                                alt=""
                            />
                            <h1 className="text-2xl font-bold text-trev text-center mb-3">
                                Login Page
                            </h1>
                            <div className="form-group mb-6  ">
                                <InputComponent
                                    id="email"
                                    inputType="text"
                                    name="email"
                                    inputPlaceholder="Email ID"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    touched={formik.touched.email}
                                    error={formik.errors.email}
                                />
                            </div>
                            <div className="form-group relative mb-6">
                                <InputComponent
                                    id="password"
                                    inputType={
                                        passwordEye === false
                                            ? 'password'
                                            : 'text'
                                    }
                                    name="password"
                                    inputPlaceholder="Password"
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    touched={formik.touched.password}
                                    error={formik.errors.password}
                                />
                                <div className="text-2xl absolute top-2 right-3">
                                    {passwordEye === false ? (
                                        <AiFillEyeInvisible
                                            onClick={handlePasswordClick}
                                        />
                                    ) : (
                                        <AiFillEye
                                            onClick={handlePasswordClick}
                                        />
                                    )}
                                </div>
                            </div>
                            <FormComponent />
                            <ButtonComp buttonHeading="Login" />
                            <ToastContainer />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    ); //
}
