import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useGetEmployeeUsingTokenQuery } from './api/generated';
import NewSubPackage from './components/Seopackage/NewSubPackage';
import UpdatePackageModal from './components/Seopackage/UpdatePackageModal';
import UpdateSubPackage from './components/Seopackage/UpdateSubPackage';
import { TOKEN } from './constants/storageKeys';
import { AddNewDomain } from './pages/admin/AddDomain';
import AdmHome from './pages/admin/AdmHome';
import Profile from './pages/admin/AdmProfile';
import { ClientOrders } from './pages/admin/ClientOrders';
import { ContentOrders } from './pages/admin/ContentOrderList';
import { CreateContentOrder } from './pages/admin/CreateContentOrder';
import { CreateOrder } from './pages/admin/CreateOrder';
import { CreateContentOrderServiceTypes } from './pages/admin/CreateServiceTypes';
import EmpLoyeeList from './pages/admin/EmployeeList';
import { CreateNiche } from './pages/admin/Niche';
import PackageSheet from './pages/admin/PackageSheet';
import { PotentialSites } from './pages/admin/PotentialSites';
import SignUpPage from './pages/admin/SignUpPage';
import { ViewOrderPage } from './pages/admin/ViewOrderPage';
import { WebsiteOutreachPool } from './pages/admin/WebsiteOutreachPool';
import ForgotPassword from './pages/employee/ForgotPassword';
import Home from './pages/employee/Home';
import LoginPage from './pages/employee/Login';
import ResetPasswordPage from './pages/employee/ResetPassword';
import { CmsClientOrders } from './pages/employee/cms/CmsClientOrderList';
import CmsHome from './pages/employee/cms/CmsHome';

import UpdatePackage from './components/Seopackage/UpdatePackage';
import CmsAdminViewOrderPage from './pages/admin/admin_cms/CmsAdminViewOrderPage';

import { ClientSelectedSites } from './pages/admin/ClientSelectedSites';
import { ViewContentOrderPage } from './pages/admin/ViewContentOrderPage';
import ViewEmpProf from './pages/admin/ViewEmpProf';

import { CmsAdminClientOrders } from './pages/admin/admin_cms/CmsAdminClientOrders';
import { SeoClientOrders } from './pages/employee/SeoClientOrderList';
import { TransferDomain } from './pages/employee/TransferDomain';
import { ViewDomain } from './pages/employee/ViewDomain';
import CmsEmpViewOrderPage from './pages/employee/cms/CmsEmpViewOrderPage';
import SeoHome from './pages/employee/seo/SeoHome';
import { SeoViewOrderPage } from './pages/employee/seo/SeoViewOrderPage';
import TransferDomainList from './pages/employee/seo/TransferDomainList';
import { ViewTransferDomainRequestPage } from './pages/employee/seo/ViewTransferDomainRequestPage';

function App() {
    const { data: TokenEmployee } = useGetEmployeeUsingTokenQuery();
    return (
        <div className="bg-zedex-bg-gray">
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/reset-password"
                        element={<ResetPasswordPage />}
                    />

                    <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                    />

                    {localStorage.getItem(TOKEN) ? (
                        <>
                            {TokenEmployee?.getEmployeeUsingToken?.role ===
                                'ADMIN' ? (
                                <>
                                    <Route path="/" element={<Home />} />
                                    <Route
                                        path="/admin/order-list"
                                        element={<ClientOrders />}
                                    />
                                    <Route
                                        path="/admin/content-order-list"
                                        element={<ContentOrders />}
                                    />
                                    <Route
                                        path="/admin/outreach-sheet/add-domain"
                                        element={<AddNewDomain />}
                                    />
                                    <Route
                                        path="/admin/add-employee"
                                        element={<SignUpPage />}
                                    />
                                    <Route
                                        path="/domain-transfer"
                                        element={<TransferDomain />}
                                    />
                                    <Route
                                        path="/admin/order-list/view-order"
                                        element={<ViewOrderPage />}
                                    />

                                    <Route
                                        path="/admin/outreach-sheet/add-domain"
                                        element={<AddNewDomain />}
                                    />
                                    <Route
                                        path="/admin/emp-list"
                                        element={<EmpLoyeeList />}
                                    />
                                    <Route
                                        path="/admin/emp-list/view-emp"
                                        element={<ViewEmpProf />}
                                    />

                                    <Route
                                        path="/admin/outreach-sheet"
                                        element={<WebsiteOutreachPool />}
                                    />
                                    <Route
                                        path="/packages"
                                        element={<PackageSheet />}
                                    />
                                    {/* <Route
                                        path="/newsubpackage"
                                        element={<NewSubPackage />}
                                    /> */}

                                    <Route
                                        path="/updatepackage/:type"
                                        element={<UpdatePackage />}
                                    />

                                    <Route
                                        path="/updatesubpackage/:type"
                                        element={<UpdateSubPackage />}
                                    />
                                </>
                            ) : null}
                            {TokenEmployee?.getEmployeeUsingToken?.role ===
                                'ADMIN' ||
                                TokenEmployee?.getEmployeeUsingToken?.role ===
                                'SEOADMIN' ? (
                                <Route
                                    path="/admin/create-order"
                                    element={<CreateOrder />}
                                />
                            ) : null}

                            {TokenEmployee?.getEmployeeUsingToken?.role ===
                                'SEOADMIN' ||
                                TokenEmployee?.getEmployeeUsingToken?.role ===
                                'SEOEMPLOYEE' ? (
                                <>
                                    <Route path="/" element={<SeoHome />} />
                                    <Route
                                        path="/seo/employee/order-list"
                                        element={<SeoClientOrders />}
                                    />
                                    <Route
                                        path="/admin/create-order"
                                        element={<CreateOrder />}
                                    />
                                </>
                            ) : null}
                            {TokenEmployee?.getEmployeeUsingToken?.role ===
                                'CMSADMIN' ||
                                TokenEmployee?.getEmployeeUsingToken?.role ===
                                'CMSEMPLOYEE' ? (
                                <>
                                    <Route path="/" element={<CmsHome />} />
                                    <Route
                                        path="/cms/employee/order-list"
                                        element={<CmsClientOrders />}
                                    />
                                </>
                            ) : null}

                            {TokenEmployee?.getEmployeeUsingToken?.role ===
                                'CMSADMIN' ? (
                                <Route
                                    path="/admin/cms/order-list"
                                    element={<CmsAdminClientOrders />}
                                />
                            ) : null}

                            <Route
                                path="/admin/outreach-sheet"
                                element={<WebsiteOutreachPool />}
                            />
                            <Route
                                path="/admin/potential-sheet"
                                element={<PotentialSites />}
                            />
                            <Route
                                path="/admin/client-selected-sites"
                                element={<ClientSelectedSites />}
                            />
                            <Route
                                path="/admin/content-order-list/content-order-service-types"
                                element={<CreateContentOrderServiceTypes />}
                            />
                            <Route
                                path="/admin/outreach-sheet/categories"
                                element={<CreateNiche />}
                            />
                            <Route
                                path="/admin/create-content-order"
                                element={<CreateContentOrder />}
                            />
                            <Route
                                path="/admin/order-list"
                                element={<ClientOrders />}
                            />
                            <Route
                                path="/seo/employee/order-list"
                                element={<SeoClientOrders />}
                            />
                            <Route
                                path="cms/employee/order-list"
                                element={<CmsClientOrders />}
                            />
                            <Route
                                path="/admin/outreach-sheet/add-domain"
                                element={<AddNewDomain />}
                            />
                            <Route path="/admin/Home" element={<AdmHome />} />
                            <Route
                                path="/admin/order-list"
                                element={<ClientOrders />}
                            />
                            <Route
                                path="/admin/cms/order-list"
                                element={<CmsAdminClientOrders />}
                            />
                            <Route
                                path="/admin/outreach-sheet/add-domain"
                                element={<AddNewDomain />}
                            />
                            <Route
                                path="/admin/add-employee"
                                element={<SignUpPage />}
                            />
                            {TokenEmployee?.getEmployeeUsingToken?.role ===
                                'CMSADMIN' ||
                                TokenEmployee?.getEmployeeUsingToken?.role ===
                                'CMSEMPLOYEE' ? (
                                <Route
                                    path="/cms/employee/order-list"
                                    element={<CmsClientOrders />}
                                />
                            ) : null}

                            <Route
                                path="/admin/packages"
                                element={<PackageSheet />}
                            />
                            {/* <Route
                                path="/admin/newpackage"
                                element={<NewPackage />}
                            /> */}
                            {/* <Route
                                path="/admin/newsubpackage"
                                element={<NewSubPackage />}
                            /> */}

                            <Route
                                path="/updatepackage/:type"
                                element={<UpdatePackageModal />}
                            />
                            <Route
                                path="/updatesubpackage/:type"
                                element={<UpdateSubPackage />}
                            />
                            <Route path="/profile-page" element={<Profile />} />
                            <Route
                                path="/admin/order-list/view-order"
                                element={<ViewOrderPage />}
                            />
                            <Route
                                path="/admin/content-order-list/view-content-order"
                                element={<ViewContentOrderPage />}
                            />
                            <Route
                                path="/admin/content-order-list"
                                element={<ContentOrders />}
                            />
                            <Route
                                path="/admin/outreach-sheet/domain"
                                element={<ViewDomain />}
                            />
                            <Route
                                path="/admin/cms/order-list/view-order"
                                element={<CmsAdminViewOrderPage />}
                            />

                            <Route
                                path="/cms/employee/order-list/view-order"
                                element={<CmsEmpViewOrderPage />}
                            />
                            <Route
                                path="/seo/employee/order-list/view-order"
                                element={<SeoViewOrderPage />}
                            />
                            <Route
                                path="/domain-transfer"
                                element={<TransferDomain />}
                            />

                            <Route
                                path="seo/employee/transfer-domain-list"
                                element={<TransferDomainList />}
                            />
                            <Route
                                path="/seo/employee/transfer-domain-list/request"
                                element={<ViewTransferDomainRequestPage />}
                            />

                            <Route
                                path="/admin/order-list/view-order"
                                element={<ViewOrderPage />}
                            />
                            <Route path="/profile-page" element={<Profile />} />
                            {/* <Route path="/test" element={<ToolTest />} /> */}
                        </>
                    ) : (
                        <Route path="/" element={<LoginPage />} />
                    )}
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
