import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import DataGrid from 'react-data-grid';
import {
    MasterSheet,
    useGetAllServiceTypeQuery,
    useGetPaginatedPotentialSitesForClientQuery,
} from '../../api/generated';
import '../../styles/WebsiteOutreachPool.css';
import { WebsiteOutreachPoolInitialValues } from '../../types/WebsiteOutreachPoolInitialValues';
import { CopyIcon } from '../main/CopyIcon';
import {
    TableHeaderColumnName,
    TableHeaderColumnSorting,
} from '../main/TableHeaderColumnSorting';

function TableRow(props: MasterSheet) {
    const formik = useFormikContext<WebsiteOutreachPoolInitialValues>();
    const contactTypeFilteredDomainDetails =
        formik.values.contactTypeFilter.length === 2
            ? props.domainDetails
            : props.domainDetails.filter((domainDetail) => {
                  return domainDetail?.contactType?.contactType?.includes(
                      formik.values.contactTypeFilter[0]
                  );
              });
    const [contactType, setContactType] = useState('ADMIN');
    const contactTypesAvailable: string[] = [];
    const domainDetails = contactTypeFilteredDomainDetails.filter(
        (domainDetail) => {
            contactTypesAvailable.push(
                domainDetail?.contactType?.contactType as string
            );
            return domainDetail?.contactType?.contactType?.includes(
                contactType
            );
        }
    );
    const { data: serviceType } = useGetAllServiceTypeQuery();
    const linkInsertionID = serviceType?.getAllServiceType?.filter(
        (type) => type?.serviceType === 'LINK-INSERTION'
    )[0]?.id;
    return (
        <tr className="hover:bg-blue-100">
            <td>
                <input type="checkbox" />
            </td>
            <td className="site-url underline">
                <a
                    href=" "
                    className="space-x-2"
                    onClick={(event) => event.preventDefault()}
                >
                    <span className="underline">{props.siteUrl}</span>
                    <CopyIcon text={props.siteUrl} />
                </a>
            </td>

            <td>{props.siteDA}</td>
            <td>{props.siteDR}</td>
            <td>{props.siteTraffic}</td>
            <td>
                <select
                    className="p-3 w-32 text-center bg-blue-50 hover:bg-blue-200"
                    onChange={(event) => setContactType(event.target.value)}
                >
                    {contactTypesAvailable.map((type) => (
                        <option className="p-3 w-32 text-center" key={type}>
                            {type}
                        </option>
                    ))}
                </select>
            </td>
            {domainDetails.map((domainDetail) => {
                return (
                    <>
                        <td>{domainDetail?.serviceType?.serviceType}</td>
                        <td>
                            {domainDetail?.serviceType?.id === linkInsertionID
                                ? domainDetail?.linkInsertionPrice
                                : 'NaN'}
                        </td>
                        <td>{domainDetail?.priceAsked}</td>
                        <td>{domainDetail?.finalPrice}</td>
                        <td>{domainDetail?.specialPrice}</td>
                        <td>
                            {domainDetail?.contactedBy?.firstName?.concat(
                                ` ${domainDetail.contactedBy?.lastName}`
                            )}
                        </td>
                        <td>{domainDetail?.POC}</td>
                        <td>{domainDetail?.dateOfContact.substring(0, 10)}</td>
                    </>
                );
            })}
            <td>
                {props.DomainNiches?.map((x, i) => (
                    <div className="block my-2" key={i.toString()}>
                        {x?.niche?.niche}
                    </div>
                ))}
            </td>
            <td>
                {props.siteContactDetails.map((x, i) =>
                    x?.contactType?.contactType === contactType ? (
                        <div className="block m-3" key={i.toString()}>
                            {x?.contactDetails}
                        </div>
                    ) : null
                )}
            </td>

            {domainDetails?.map((domainDetails) => {
                if (domainDetails?.status === 'Done') {
                    return (
                        <td key={domainDetails.status} className="font-bold">
                            <span className="block done-status p-4">Done</span>
                        </td>
                    );
                }
                if (domainDetails?.status === 'Negotiating') {
                    return (
                        <td key={domainDetails.status} className=" font-bold">
                            <span className="block nego-status p-4">
                                Negotiating
                            </span>
                        </td>
                    );
                }
                return <td key={domainDetails?.status} />;
            })}
        </tr>
    );
}

export function Table(props: { clientID: number }) {
    const formik = useFormikContext<WebsiteOutreachPoolInitialValues>();

    useEffect(() => {
        formik.setFieldValue('pageNum', 0);
    }, [formik.values.paginationRowCount]);

    const { data, loading } = useGetPaginatedPotentialSitesForClientQuery({
        variables: {
            input: {
                clientID: props.clientID,
                take: Number(formik.values.paginationRowCount),
                skip:
                    Number(formik.values.pageNum) *
                    Number(formik.values.paginationRowCount),
                sortBy: formik.values.sortBy,
                sortType: formik.values.sortType,
                search: {
                    ...formik.values.search,
                },
                statusFilters: formik.values.statusFilters,
                serviceTypeFilter: formik.values.serviceTypeFilter,
                nicheFilter: formik.values.nicheFilter,
                siteDaFilter: {
                    ...formik.values.siteDAFilter,
                },
                siteDrFilter: {
                    ...formik.values.siteDRFilter,
                },
                siteTrafficFilter: {
                    ...formik.values.siteTrafficFilter,
                },
                finalPriceFilter: {
                    ...formik.values.finalPriceFilter,
                },
                specialPriceFilter: {
                    ...formik.values.specialPriceFilter,
                },
                priceAskedFilter: {
                    ...formik.values.priceAskedFilter,
                },
                dateOfContactFilter: {
                    ...formik.values.dateOfContactFilter,
                },
            },
        },
    });
    useEffect(() => {
        formik.setFieldValue(
            'totalPages',
            data?.getPaginatedPotentialSitesForClient?.totalPages
        );
    }, [data?.getPaginatedPotentialSitesForClient?.totalPages]);

    useEffect(() => {
        formik.setFieldValue(
            'totalRows',
            data?.getPaginatedPotentialSitesForClient?.totalRows
        );
    }, [data?.getPaginatedPotentialSitesForClient?.totalRows]);

    return (
        <div className="website-outreach-pool-table overflow-x-auto overflow-y-auto text-center bg-transparent">
            {loading ? (
                <div className="rounded-lg p-5 bg-blue-100 text-blue-500 text-center">
                    Loading....
                </div>
            ) : data?.getPaginatedPotentialSitesForClient?.totalPages === 0 ? (
                <div className="rounded-lg p-5 bg-blue-100 text-blue-500 text-center">
                    No Data To be Displayed
                </div>
            ) : (
                <table className="table-auto">
                    <thead className="table-header text-xs font-bold">
                        <tr className="space-x-2 overflow-x-auto">
                            <th className="edit-option">
                                <input
                                    className="align-middle"
                                    type="checkbox"
                                />
                            </th>
                            <TableHeaderColumnSorting
                                columnName="Site URL"
                                sortBy="siteUrl"
                            />
                            <TableHeaderColumnSorting
                                columnName="DA"
                                sortBy="siteDA"
                            />
                            <TableHeaderColumnSorting
                                columnName="DR"
                                sortBy="siteDR"
                            />
                            <TableHeaderColumnSorting
                                columnName="Site Traffic"
                                sortBy="siteTrafffic"
                            />
                            <TableHeaderColumnName columnName="Contact Type" />
                            <TableHeaderColumnName columnName="Service Type" />
                            <TableHeaderColumnName columnName="Link Insertion Price" />
                            <TableHeaderColumnName columnName="Price Asked" />
                            <TableHeaderColumnName columnName="Final Price" />
                            <TableHeaderColumnName columnName="Special Price" />
                            <TableHeaderColumnName columnName="Contacted By" />
                            <TableHeaderColumnName columnName="POC" />
                            <TableHeaderColumnName columnName="Date of Contact" />
                            <TableHeaderColumnName columnName="Niche" />
                            <TableHeaderColumnName columnName="Site Contact Details" />
                            <TableHeaderColumnName columnName="Status" />
                        </tr>
                    </thead>
                    <tbody className="text-xs">
                        {data?.getPaginatedPotentialSitesForClient?.dms?.map(
                            (row) =>
                                row && (
                                    <TableRow
                                        id={row.id}
                                        siteUrl={row.siteUrl}
                                        siteDA={row.siteDA}
                                        siteDR={row.siteDR}
                                        ahrefTraffic={row.siteDA}
                                        siteTraffic={row.siteTraffic}
                                        createdAt={row.createdAt}
                                        updatedAt={row.updatedAt}
                                        siteContactDetails={
                                            row.siteContactDetails
                                        }
                                        DomainNiches={row.DomainNiches}
                                        domainDetails={row.domainDetails}
                                    />
                                )
                        )}
                    </tbody>
                </table>
            )}
        </div>
    );
}

interface DataGridColumn {
    key: string;
    name: string;
    sortable: true;
}

export function DataGridTable(props: {
    data: MasterSheet[];
    columns: DataGridColumn[];
}) {
    return (
        <DataGrid
            className="rdg-light"
            columns={props.columns}
            rows={props.data}
            renderers={
                {
                    // rowRenderer:,
                }
            }
            rowKeyGetter={rowKeyGetter}
        />
    );
}

function rowKeyGetter(row: MasterSheet) {
    return row.id;
}

// function RowRenderer(row: MasterSheet) {
//     return (
//         <tr className="">
//             <td>
//                 <input type="checkbox" />
//             </td>
//             <td className="site-url underline">
//                 <a href=" ">{row.siteUrl}</a>
//             </td>
//             <td>{row.siteDA}</td>
//             <td>{row.siteDR}</td>
//             <td>{row.siteTraffic}</td>
//             <td>
//                 $
//                 {row.domainDetails?.map((domainDetail, i) => (
//                     <span key={i.toString()}>{domainDetail?.priceAsked}</span>
//                 ))}
//             </td>
//             <td>
//                 $
//                 {row.domainDetails?.map(
//                     (domainDetail) => domainDetail?.finalPrice
//                 )}
//             </td>
//             <td>
//                 $
//                 {row.domainDetails?.map(
//                     (domainDetail) => domainDetail?.specialPrice
//                 )}
//             </td>
//             <td>
//                 {row.niches?.map((x, i) => (
//                     <div className="block my-2" key={i.toString()}>
//                         {x?.niche}
//                     </div>
//                 ))}
//             </td>
//             <td>
//                 {row.siteContactDetails.map((x, i) => (
//                     <div className="block my-2" key={i.toString()}>
//                         {x?.contactDetails}
//                     </div>
//                 ))}
//             </td>

//             <td>
//                 {row.domainDetails?.map((domainDetail) =>
//                     domainDetail?.contactedBy.firstName?.concat(
//                         ' ',
//                         domainDetail?.contactedBy?.lastName as string
//                     )
//                 )}
//             </td>
//             <td className="poc">
//                 {row.domainDetails?.map((domainDetail) => (
//                     <div key={domainDetail?.contactedBy?.email}>
//                         {domainDetail?.POC}
//                     </div>
//                 ))}
//             </td>
//             <td>
//                 {row.domainDetails?.map(
//                     (domainDetail) => domainDetail?.contactType.contactType
//                 )}
//             </td>
//             <td>
//                 {row.domainDetails?.map((domainDetail) =>
//                     domainDetail?.dateOfContact.substring(0, 10)
//                 )}
//             </td>
//             <td>{row.createdAt?.substring(0, 10)}</td>
//             <td>{row.updatedAt?.substring(0, 10)}</td>
//             {row.domainDetails?.map((domainDetails) => {
//                 if (domainDetails?.status === 'Done') {
//                     return (
//                         <td key={domainDetails.status} className="font-bold">
//                             <span className="block done-status p-4">Done</span>
//                         </td>
//                     );
//                 }
//                 if (domainDetails?.status === 'Negotiating') {
//                     return (
//                         <td key={domainDetails.status} className=" font-bold">
//                             <span className="block nego-status p-4">
//                                 Negotiating
//                             </span>
//                         </td>
//                     );
//                 }
//                 return <td key={domainDetails?.status} />;
//             })}
//         </tr>
//     );
// }
